import * as http from './http';
import ApiService from './service';

const serviceEndpoint = 'user/v1';

export default class RegistrationService extends ApiService {
  public async updateDetails(data: any) {
    const url = `${this.apiDomain}/${serviceEndpoint}/update-profile`;
    return http.put(url, data, this.token);
  }

  public async getCourses(preApplyPromoCode: boolean,promoCode: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/courses?preApplyPromotion=${preApplyPromoCode}&promoCode=${promoCode}`;
    return http.get(url, this.token);
  }

  public async chooseCourse(data: any) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/course`;
    return http.post(url, data, this.token);
  }

  public async getCourse() {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/course`;
    return http.get(url, this.token);
  }

  public async initiatePayment(data: any) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/course/payment`;
    return http.post(url, data, this.token);
  }

  public async getPromotions(courseId: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/promotions?courseId=${courseId}`;
    return http.get(url, this.token);
  }

  public async applyPromotion(data: any) {
    const url = `${this.apiDomain}/${serviceEndpoint}/apply-promotion`;
    return http.post(url, data, this.token);
  }

  public async validateReferenceNumber(data: any) {
    const url = `${this.apiDomain}/pricing/v1/promotion/validate-reference-number`;
    return http.post(url, data, this.token);
  }

  public async removePromotion() {
    const data = {};
    const url = `${this.apiDomain}/${serviceEndpoint}/remove-promotion`;
    return http.post(url, data, this.token);
  }
}
