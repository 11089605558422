import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  RadioSelectInput,
  DateInput,
  TextInput,
} from '../../components/form-group';
import Button from '../../components/button';

import { actions as registrationActions } from '../../store/reducers/registration';
import { actions as userActions } from '../../store/reducers/authUser';

import { validateFields, serializeFields, validators } from '../helpers';
import SelectInput from '../../components/form-group/SelectInput';
import User, { LicenseDetails, UAELicenseDetails } from '../../models/user';
import UAELicenseTypeForm from './UAELicenseTypeForm';
import { LocationInput } from '../../components/location-autocomplete';
import LocationSearchInput from '../../components/location-autocomplete/LocationInput';

const UAECountryName = 'United Arab Emirates';
const PilotOrCabinCrewProfessions = ['Pilot', 'Cabin Crew'];
const OtherVisaProfession = 'Other';

interface CustomProps {
  isLoading?: boolean;
  onSubmit?: (data: any, fromRTA?: boolean) => void;
  addSummary?: (data: any) => void;
  user?: User;
  gotoNextBlock?: () => any;
  fetchProfile?: () => any;
}

interface CustomState {
  fields: { [key: string]: any };
  validateUAELicences: boolean;
}
class PersonalDetail extends Component<CustomProps, CustomState> {
  constructor(props: CustomProps) {
    super(props);
    this.state = {
      fields: this.getFields(props),
      validateUAELicences: false,
    };

    this.setInput = this.setInput.bind(this);
    this.getInput = this.getInput.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.validateLicenceFields = this.validateLicenceFields.bind(this);
    this.validateEmirateField = this.validateEmirateField.bind(this);
    this.validateLicenseEmirateField = this.validateLicenseEmirateField.bind(
      this
    );

    this.requiredWhenHasLicence = this.requiredWhenHasLicence.bind(this);
    this.requiredWhenLicenceUAESelected = this.requiredWhenLicenceUAESelected.bind(
      this
    );
    this.requiredWhenNationalityUAESelected = this.requiredWhenNationalityUAESelected.bind(
      this
    );
    this.customeValidations = this.customeValidations.bind(this);
    this.setAddressValue = this.setAddressValue.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  getHasUAELicenseField(props: CustomProps) {
    return {
      value:
        (props.user &&
          props.user.studentDetails &&
          props.user.studentDetails.hasUAELicense) ||
        false,
      name: 'Has UAE Licence',
      label: 'Do you have a valid UAE driving license?',
      error: '',
    };
  }

  // eslint-disable-next-line class-methods-use-this
  getLicenceEmirateField(props: CustomProps) {
    return {
      value:
        (props.user &&
          props.user.studentDetails &&
          props.user.studentDetails.licenseEmirate) ||
        '',
      name: 'Licence From Which Emirate',
      label: 'Licence From Which Emirate *',
      error: '',
      placeholder: 'Choose Emirate',
    };
  }

  // eslint-disable-next-line class-methods-use-this
  getLicenceExpiryDateField(props: CustomProps) {
    return {
      value:
        (props.user &&
          props.user.studentDetails &&
          props.user.studentDetails.licenseExpiryDate) ||
        '',
      name: 'Licence Valid To',
      label: 'Valid To *',
      error: '',
      placeholder: 'DD / MM / YYYY',
    };
  }
  // eslint-disable-next-line class-methods-use-this
  getUAELicencesFormGroup(props: CustomProps) {
    const UAELicenses: Array<Partial<LicenseDetails>> =
      (props.user &&
        props.user.studentDetails &&
        props.user.studentDetails.UAELicenseTypes) ||
      [];
    if (UAELicenses.length === 0) {
      UAELicenses.push({});
    }
    return {
      value: UAELicenses,
      name: 'UAE Licenses',
      label: 'UAE License Details *',
      error: '',
    };
  }

  // eslint-disable-next-line class-methods-use-this
  getLicenceIssueField(props: CustomProps) {
    return {
      value:
        (props.user &&
          props.user.studentDetails &&
          props.user.studentDetails.licenseIssueDate) ||
        '',
      name: 'Licence Valid From',
      label: 'Valid From *',
      error: '',
      placeholder: 'DD / MM / YYYY',
    };
  }

  // eslint-disable-next-line class-methods-use-this
  getVisaProfessionField(props: CustomProps) {
    return {
      value:
        (props.user &&
          props.user.studentDetails &&
          props.user.studentDetails.visaProfession) ||
        '',
      name: 'Visa Profession',
      label: 'Visa Profession *',
      placeholder: 'Select Visa Profession',
      error: '',
      validators: [
        validators.required,
        validators.regexPattern(/^[a-zA-Z -/]+$/),
      ],
    };
  }

  getVisaProfessionOtherField(props: CustomProps) {
    return {
      value:
        (props.user &&
          props.user.studentDetails &&
          props.user.studentDetails.visaProfession) ||
        '',
      name: 'Visa Profession',
      label: 'Visa Profession - Other *',
      placeholder: 'Enter your Visa Profession',
      error: '',
      validators: [],
    };
  }

  // eslint-disable-next-line class-methods-use-this
  getNationalityField(props: CustomProps) {
    return {
      value: (props.user && props.user.nationality) || '',
      name: 'Nationality',
      label: 'Nationality *',
      error: '',
      placeholder: 'Select Country',
      validators: [validators.required],
    };
  }

  // eslint-disable-next-line class-methods-use-this
  getLicenceCountryField(props: CustomProps) {
    return {
      value:
        (props.user &&
          props.user.studentDetails &&
          props.user.studentDetails.licenseCountry) ||
        '',
      name: 'Driving License Issuer Country',
      label: 'Driving License Issuer Country *',
      error: '',
      placeholder: 'Select Country',
      validators: [],
    };
  }

  // eslint-disable-next-line class-methods-use-this
  getHasLicenceField(props: CustomProps) {
    return {
      value:
        (props.user &&
          props.user.studentDetails &&
          props.user.studentDetails.hasLicense) ||
        false,
      name: `${this.props.user &&
        this.props.user.studentDetails &&
        this.props.user.studentDetails.requiredLicenseCategory} license status`,
      label: `Do you have a valid ${this.props.user &&
        this.props.user.studentDetails
          .requiredLicenseCategory} driving license? *`,
      error: '',
      validators: [validators.required],
    };
  }
  // eslint-disable-next-line class-methods-use-this
  getIsCabinCrewOrPilotField(props: CustomProps) {
    return {
      value:
        (props.user &&
          props.user.studentDetails &&
          props.user.studentDetails.isCabinCrewOrPilot) ||
        false,
      name: `Cabin Crew/Pilot of Emirate Airlines/Fly Dubai Status`,
      label: `Are you Cabin Crew/Pilot of Emirate Airlines/Fly Dubai? *`,
      error: '',
      validators: [validators.required],
    };
  }

  // eslint-disable-next-line class-methods-use-this
  getAddressField(props: CustomProps) {
    return {
      value: (props.user && props.user.address) || '',
      name: 'Address',
      label: 'Address *',
      error: '',
      placeholder: 'Select your location',
      validators: [validators.required],
    };
  }

  // eslint-disable-next-line class-methods-use-this
  getEmirateField(props: CustomProps) {
    return {
      value:
        (props.user &&
          props.user.studentDetails &&
          props.user.studentDetails.emirate) ||
        '',
      name: 'Emirate',
      label: 'Emirate *',
      error: '',
      placeholder: 'Select Emirate',
      validators: [],
    };
  }

  getOccupationField(props: CustomProps) {
    return {
      value:
        (props.user &&
          props.user.studentDetails &&
          props.user.studentDetails.occupation) ||
        '',
      name: 'Occupation',
      label: 'Occupation *',
      error: '',
      placeholder: 'Select your Occupation',
      validators: [
        validators.required,
        validators.regexPattern(/^[a-zA-Z -]+$/),
      ],
    };
  }

  getGoldenOpertunityCustomerField(props: CustomProps) {
    return {
      value: false,
      name: 'Golden Opportunity user',
      label: 'Golden Opportunity user',
      error: '',
      placeholder: '',
      validators: [],
    };
  }

  getFields(props: CustomProps) {
    return {
      emirate: this.getEmirateField(props),
      occupation: this.getOccupationField(props),
      visaProfessionOther: this.getVisaProfessionOtherField(props),
      hasLicense: this.getHasLicenceField(props),
      licenseCountry: this.getLicenceCountryField(props),
      nationality: this.getNationalityField(props),
      visaProfession: this.getVisaProfessionField(props),
      licenseIssueDate: this.getLicenceIssueField(props),
      licenseExpiryDate: this.getLicenceExpiryDateField(props),
      licenseEmirate: this.getLicenceEmirateField(props),
      isCabinCrewOrPilot: this.getIsCabinCrewOrPilotField(props),
      hasUAELicense: this.getHasUAELicenseField(props),
      UAELicenses: this.getUAELicencesFormGroup(props),
      address: this.getAddressField(props),
      isGoldenOpertunityCustomer: this.getGoldenOpertunityCustomerField(props),
    };
  }

  validateLicenseTypes(licenseTypes: UAELicenseDetails[]) {
    if (!this.validateUAELicenceFields()) {
      return '';
    }

    if (!licenseTypes || licenseTypes.length === 0) {
      return `Please add atleast one UAE License`;
    }

    if (
      licenseTypes.find(
        (lt) =>
          !lt.licenseTypeId ||
          !lt.licenseExpiryDate ||
          !lt.licenseIssueDate ||
          !lt.licenseEmirate
      )
    ) {
      return 'Please correct UAE License Details';
    }

    return '';
  }

  requiredWhenHasLicence(field: string, value: any, label: string) {
    if (!this.validateLicenceFields()) {
      return '';
    }

    const fieldLabel = label || field;
    // console.log('field: ', field: string, value: any, label: string);
    if (value === '' || value === undefined) return `${fieldLabel} is required`;
    return '';
  }

  requiredWhenLicenceUAESelected(field: string, value: any, label: string) {
    if (!this.validateLicenseEmirateField()) {
      return '';
    }

    const fieldLabel = label || field;
    // console.log('field: ', field: string, value: any, label: string);
    if (value === '' || value === undefined) return `${fieldLabel} is required`;
    return '';
  }

  requiredWhenNationalityUAESelected(field: string, value: any, label: string) {
    if (!this.validateEmirateField()) {
      return '';
    }

    const fieldLabel = label || field;
    // console.log('field: ', field: string, value: any, label: string);
    if (value === '' || value === undefined) return `${fieldLabel} is required`;
    return '';
  }

  validateWhenVisaProfessionSeletedOther = (
    field: string,
    value: any,
    label: string
  ) => {
    if (!this.validateVisaProfessionOther()) {
      return '';
    }

    const fieldLabel = label || field;
    // console.log('field: ', field: string, value: any, label: string);
    if (value === '' || value === undefined) return `${fieldLabel} is required`;
    if (!/^[a-zA-Z -/]+$/.test(value)) return `Invalid ${fieldLabel}`;
    return '';
  };

  validateAddress = (field: string, value: any, label: string) => {
    const fieldLabel = label || field;
    // console.log('field: ', field: string, value: any, label: string);
    if (value && (value.address === '' || value.address === undefined)) {
      return `${fieldLabel} is required`;
    }

    return '';
  };

  hideHasLicenceField() {
    return (
      this.props.user &&
      this.state.fields.hasUAELicense.value &&
      this.props.user.studentDetails &&
      this.props.user.studentDetails.requiredLicenseCategory &&
      this.state.fields.UAELicenses.value.find(
        (license: UAELicenseDetails) =>
          this.props.user &&
          license.licenseTypeId ===
            this.props.user.studentDetails.requiredLicenseId
      )
    );
  }

  validateLicenceFields() {
    return this.state.fields.hasLicense.value;
  }

  validateUAELicenceFields() {
    return this.state.fields.hasUAELicense.value;
  }

  validateLicenseEmirateField() {
    return (
      this.state.fields.hasLicense.value &&
      this.state.fields.licenseCountry.value === UAECountryName
    );
  }

  validateEmirateField() {
    return this.state.fields.nationality.value === UAECountryName;
  }
  validateCabinCrewOrPilotOption() {
    return (
      PilotOrCabinCrewProfessions.includes(
        this.state.fields.visaProfession.value
      ) ||
      (this.state.fields.visaProfession.value === OtherVisaProfession &&
        PilotOrCabinCrewProfessions.includes(
          this.state.fields.visaProfessionOther.value
        ))
    );
  }

  validateVisaProfessionOther = () => {
    return this.state.fields.visaProfession.value === OtherVisaProfession;
  };

  async componentDidMount() {
    if (this.props.fetchProfile) await this.props.fetchProfile();
  }

  setInput(inputField: string, value: any) {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [inputField]: {
          ...prevState.fields[inputField],
          value,
        },
      },
    }));

    if(inputField === 'hasLicense' && value === false){
      const tempInputField = 'isGoldenOpertunityCustomer';
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          [tempInputField]: {
            ...prevState.fields[tempInputField],
            value,
          },
        },
      }));
    }
  }

  getInput(inputField: string) {
    return this.state.fields[inputField] && this.state.fields[inputField].value;
  }

  customeValidations() {
    let areCustomFieldsValid = true;
    const fields: { [key: string]: any } = {
      licenseIssueDate: this.state.fields.licenseIssueDate,
      licenseExpiryDate: this.state.fields.licenseExpiryDate,
      licenseEmirate: this.state.fields.licenseEmirate,
      licenseCountry: this.state.fields.licenseCountry,
      emirate: this.state.fields.emirate,
      visaProfessionOther: this.state.fields.visaProfessionOther,
      address: this.state.fields.address,
    };

    const customValidationKeys = [
      'licenseIssueDate',
      'licenseExpiryDate',
      'licenseCountry',
    ];

    for (const key of customValidationKeys) {
      if (!fields[key]) {
        fields[key] = {};
      }
      const validatorMessage = this.requiredWhenHasLicence(
        key,
        fields[key].value,
        fields[key].name
      );
      fields[key].error = validatorMessage;

      if (validatorMessage.length) {
        areCustomFieldsValid = false;
      }
    }

    const licenseEmirateValidatorMessage = this.requiredWhenLicenceUAESelected(
      'licenseEmirate',
      fields.licenseEmirate.value,
      fields.licenseEmirate.name
    );
    fields.licenseEmirate.error = licenseEmirateValidatorMessage;

    if (licenseEmirateValidatorMessage.length) {
      areCustomFieldsValid = false;
    }
    const emirateValidatorMessage = this.requiredWhenNationalityUAESelected(
      'emirate',
      fields.emirate.value,
      fields.emirate.name
    );
    fields.emirate.error = emirateValidatorMessage;

    if (emirateValidatorMessage.length) {
      areCustomFieldsValid = false;
    }

    const otherVisaProfessionValidatorMessage = this.validateWhenVisaProfessionSeletedOther(
      'visaProfessionOther',
      fields.visaProfessionOther.value,
      fields.visaProfessionOther.name
    );
    fields.visaProfessionOther.error = otherVisaProfessionValidatorMessage;

    const AddressValidationMessage = this.validateAddress(
      'address',
      fields.address.value,
      fields.address.name
    );
    fields.address.error = AddressValidationMessage;

    if (
      otherVisaProfessionValidatorMessage.length ||
      AddressValidationMessage.length
    ) {
      areCustomFieldsValid = false;
    }
    if (!AddressValidationMessage.length) {
      if (fields.address.value) {
        fields.address.value.latitude = fields.address.value.latitude?.toString();
        fields.address.value.longitude = fields.address.value.longitude?.toString();
      }
    }

    return { areCustomFieldsValid, updatedCustomFields: fields };
  }

  submitHandler(e: any) {
    e.preventDefault();
    // this.props.addSummary({ key: 'foo', label: 'foo', value: 'bar' });
    if (this.props.addSummary) {
      this.props.addSummary({
        key: 'licensetype',
        label: 'Your required license type',
        value:
          this.props.user &&
          this.props.user.studentDetails.requiredLicenseCategory,
      });
      if (this.state.fields.nationality.value) {
        this.props.addSummary({
          key: 'nationality',
          label: 'and Nationality is',
          value: this.state.fields.nationality.value,
        });
      }
    }

    if (this.state.fields.hasUAELicense.value) {
      const existingLicense: UAELicenseDetails =
        this.state.fields.UAELicenses &&
        this.state.fields.UAELicenses.value &&
        this.state.fields.UAELicenses.value.find(
          (license: UAELicenseDetails) =>
            this.props.user &&
            license.licenseTypeId ===
              this.props.user.studentDetails.requiredLicenseId
        );

      if (existingLicense) {
        this.state.fields.hasLicense.value = true;
        this.state.fields.licenseCountry.value = UAECountryName;
        this.state.fields.licenseIssueDate.value =
          existingLicense.licenseIssueDate;
        this.state.fields.licenseExpiryDate.value =
          existingLicense.licenseExpiryDate;
        this.state.fields.licenseEmirate.value = existingLicense.licenseEmirate;
      }
    }

    if (
      this.state.fields.hasLicense.value &&
      this.state.fields.licenseCountry.value === UAECountryName &&
      !this.state.fields.hasUAELicense.value
    ) {
      this.state.fields.hasUAELicense.value = true;
      if (!this.state.fields.UAELicenses) {
        this.state.fields.UAELicenses = {};
      }
      const uaeLicenses: UAELicenseDetails[] = [
        {
          licenseEmirate: this.state.fields.licenseEmirate.value,
          licenseExpiryDate: this.state.fields.licenseExpiryDate.value,
          licenseIssueDate: this.state.fields.licenseIssueDate.value,
          licenseTypeId: this.props.user
            ? this.props.user.studentDetails.requiredLicenseId
            : '',
        },
      ];
      this.state.fields.UAELicenses.value = uaeLicenses;
    }

    const validateOptions = {
      fieldsToValidate: this.state.fields,
    };
    const { areFieldsValid, updatedFields } = validateFields(validateOptions);
    // Custom validations

    const {
      areCustomFieldsValid,
      updatedCustomFields,
    } = this.customeValidations();

    const isLicenseTypesValidationMessage = this.validateLicenseTypes(
      this.state.fields.UAELicenses.value
    );

    const licenseTypeValidationFailed = !!isLicenseTypesValidationMessage;

    // show error messages (if any)
    this.setState((prevState) => ({
      fields: { ...prevState.fields, ...updatedFields, ...updatedCustomFields },
      validateUAELicences: licenseTypeValidationFailed,
    }));

    if (
      !licenseTypeValidationFailed &&
      areFieldsValid &&
      areCustomFieldsValid
    ) {
      const readyFields = serializeFields(this.state.fields);
      this.submitForm(readyFields);
    }
  }

  async submitForm(readyFields: any) {
    const data = this.prepareData(readyFields);
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    let fromRTA = false
    if(token) {
      fromRTA = true
    }
    try {
      if (this.props.onSubmit) this.props.onSubmit(data,fromRTA);
      if (this.props.gotoNextBlock) this.props.gotoNextBlock();
    } catch (error) {
      // console.log('Login error:', error);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  prepareData(formData: any) {
    const {
      hasLicense,
      licenseCountry,
      licenseIssueDate,
      licenseExpiryDate,
      licenseEmirate,
      occupation,
      visaProfessionOther,
      visaProfession,
      emirate,
      nationality,
      hasUAELicense,
      UAELicenses,
      isCabinCrewOrPilot,
      address,
      isGoldenOpertunityCustomer,
    } = formData;

    const finalData = {
      // dob,
      // gender,
      nationality,
      address,
      studentDetails: {
        emirate,
        occupation,
        hasLicense,
        licenseCountry,
        visaProfession:
          visaProfession === OtherVisaProfession
            ? visaProfessionOther
            : visaProfession,
        isLicenseExpired: false,
        experienceInYears: 5.5,
        licenseIssueDate: hasLicense ? licenseIssueDate : undefined,
        licenseExpiryDate: hasLicense ? licenseExpiryDate : undefined,
        licenseEmirate: hasLicense ? licenseEmirate : undefined,
        hasUAELicense,
        UAELicenseTypes: hasUAELicense ? UAELicenses : [],
        isCabinCrewOrPilot:
          this.validateCabinCrewOrPilotOption() && isCabinCrewOrPilot,
      },
      isGoldenOpertunityCustomer,
    };

    return finalData;
  }

  // eslint-disable-next-line class-methods-use-this
  renderFormHeader() {
    return (
      <div className="col-12">
        <p className="font-base text-body">Enter your personal details</p>
      </div>
    );
  }

  renderNationalityInput() {
    return (
      <SelectInput
        api="entity/v1/countries"
        label={this.state.fields.nationality.label}
        placeholder={this.state.fields.nationality.placeholder}
        optionLabel="name"
        optionValue="name"
        onChange={(value) => this.setInput('nationality', value)}
        error={this.state.fields.nationality.error}
        default={this.getInput('nationality')}
        from={"nationality"}
        disabled={this.props.isLoading}
      />
    );
  }

  renderEmirateInput() {
    return (
      <SelectInput
        api="entity/v1/emirates"
        label={this.state.fields.emirate.label}
        onChange={(value) => this.setInput('emirate', value)}
        placeholder={this.state.fields.emirate.placeholder}
        error={this.state.fields.emirate.error}
        default={this.getInput('emirate')}
        disabled={this.props.isLoading}
        optionLabel="name"
        optionValue="name"
        // data={emirates.map(em => ({ name: em }))}
      />
    );
  }

  renderOccupationInput() {
    return (
      <SelectInput
        api="entity/v1/occupation"
        optionLabel="name"
        optionValue="name"
        label={this.state.fields.occupation.label}
        onChange={(value) => this.setInput('occupation', value)}
        placeholder={this.state.fields.occupation.placeholder}
        error={this.state.fields.occupation.error}
        default={this.getInput('occupation')}
        disabled={this.props.isLoading}
      />
    );
  }
  renderVisaProfessionOtherInput() {
    return (
      <TextInput
        label={this.state.fields.visaProfessionOther.label}
        onChange={(value) => this.setInput('visaProfessionOther', value)}
        placeholder={this.state.fields.visaProfessionOther.placeholder}
        error={this.state.fields.visaProfessionOther.error}
        default={this.getInput('visaProfessionOther')}
        disabled={this.props.isLoading}
      />
    );
  }

  renderVisaProfessionInput() {
    return (
      <SelectInput
        api="entity/v1/visa-profession"
        optionLabel="name"
        optionValue="name"
        label={this.state.fields.visaProfession.label}
        onChange={(value) => this.setInput('visaProfession', value)}
        placeholder={this.state.fields.visaProfession.placeholder}
        error={this.state.fields.visaProfession.error}
        default={this.getInput('visaProfession')}
        disabled={this.props.isLoading}
        defaultIfNotFound={OtherVisaProfession}
      />
    );
  }

  renderHasLicenceInput() {
    return (
      <RadioSelectInput
        type="boolean"
        name="hasLicense"
        label={this.state.fields.hasLicense.label}
        options={[
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ]}
        onChange={(value) => this.setInput('hasLicense', value)}
        error={this.state.fields.hasLicense.error}
        default={this.getInput('hasLicense')}
        // disabled={this.props.isLoading}
      />
    );
  }
  renderCabinCrewOrPilotInput() {
    return (
      <RadioSelectInput
        type="boolean"
        name="isCabinCrewOrPilot"
        label={this.state.fields.isCabinCrewOrPilot.label}
        options={[
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ]}
        onChange={(value) => this.setInput('isCabinCrewOrPilot', value)}
        error={this.state.fields.isCabinCrewOrPilot.error}
        default={this.getInput('isCabinCrewOrPilot')}
        // disabled={this.props.isLoading}
      />
    );
  }
  renderHasUAELicenceInput() {
    return (
      <RadioSelectInput
        type="boolean"
        name="hasUAELicense"
        label={this.state.fields.hasUAELicense.label}
        options={[
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ]}
        onChange={(value) => this.setInput('hasUAELicense', value)}
        error={this.state.fields.hasUAELicense.error}
        default={this.getInput('hasUAELicense')}
        // disabled={this.props.isLoading}
      />
    );
  }

  renderSubmitButton() {
    return (
      <div className="row">
        <div className="col-md-6 col-12">
          <Button type="submit" disabled={this.props.isLoading} expand>
            {this.props.isLoading ? 'Please wait' : 'Next'}
          </Button>
        </div>
      </div>
    );
  }

  renderLicenceEmirateInput() {
    return (
      <SelectInput
        api="entity/v1/emirates"
        label={this.state.fields.licenseEmirate.label}
        onChange={(value) => this.setInput('licenseEmirate', value)}
        placeholder={this.state.fields.licenseEmirate.placeholder}
        error={this.state.fields.licenseEmirate.error}
        default={this.getInput('licenseEmirate')}
        disabled={this.props.isLoading}
        optionLabel="name"
        optionValue="name"
        // data={emirates.map(em => ({ name: em }))}
      />
    );
  }

  renderLicenceCountryInput() {
    return (
      <SelectInput
        api={`entity/v1/countries`}
        label={this.state.fields.licenseCountry.label}
        placeholder={this.state.fields.licenseCountry.placeholder}
        optionLabel="name"
        optionValue="name"
        onChange={(value) => this.setInput('licenseCountry', value)}
        error={this.state.fields.licenseCountry.error}
        default={this.getInput('licenseCountry')}
        from={"licenseCountry"}
        disabled={this.props.isLoading}
      />
    );
  }

  renderLicenceDates() {
    return (
      <div className="row">
        <div className="col-md-6 col-12">
          {this.renderLicenceIssueDateInput()}
        </div>
        <div className="col-md-6 col-12">
          {this.renderLicenceExpiryDateInput()}
        </div>
      </div>
    );
  }

  renderLicenceExpiryDateInput() {
    return (
      <DateInput
        label={this.state.fields.licenseExpiryDate.label}
        onChange={(value) => this.setInput('licenseExpiryDate', value)}
        placeholder={this.state.fields.licenseExpiryDate.placeholder}
        error={this.state.fields.licenseExpiryDate.error}
        default={this.getInput('licenseExpiryDate')}
        // disabled={this.props.isLoading}
        minDate={this.state.fields.licenseIssueDate.value}
      />
    );
  }

  renderLicenceIssueDateInput() {
    return (
      <DateInput
        label={this.state.fields.licenseIssueDate.label}
        onChange={(value) => this.setInput('licenseIssueDate', value)}
        placeholder={this.state.fields.licenseIssueDate.placeholder}
        error={this.state.fields.licenseIssueDate.error}
        default={this.getInput('licenseIssueDate')}
        // disabled={this.props.isLoading}
        maxDate={this.state.fields.licenseExpiryDate.value || new Date()}
      />
    );
  }
  renderLocationAddressInput() {
    return (
      <LocationInput
        label={this.state.fields.address.label}
        placeholder={this.state.fields.address.placeholder}
        setLocation={(val) => this.setAddressValue(val)}
        default={this.getInput('address')}
        error={this.state.fields.address.error}
      />
    );
  }

  setAddressValue(address: any) {
    const { location } = address;
    this.setInput('address', location);
  }

  addUAELicenceOption() {
    const licenses = [] as any;
    licenses.push(...this.state.fields.UAELicenses.value, {});
    this.setInput('UAELicenses', licenses);
  }

  deleteUAELicenseEntry(index: number) {
    const licenses = [] as any;
    licenses.push(...this.state.fields.UAELicenses.value);
    licenses.splice(index, 1);
    this.setInput('UAELicenses', licenses);
  }

  onUAELicensTypeChange(index: number, data: Partial<LicenseDetails>) {
    const licenses = [] as any;
    licenses.push(...this.state.fields.UAELicenses.value);
    if (licenses[index]) {
      licenses[index] = {
        ...licenses[index],
        ...data,
      };
    }
    this.setInput('UAELicenses', licenses);
  }

  renderGoldenOpertunityCustomerInput() {
    return (
      <RadioSelectInput
      type="boolean"
      name="isGoldenOpertunityCustomer"
      label="Do you want to continue with this course ? "
      options={[
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ]}
      onChange={(value) => this.setInput('isGoldenOpertunityCustomer', value)}
      error={this.state.fields.hasLicense.error}
      default={this.getInput('isGoldenOpertunityCustomer')}
    />

    );
  }

  render() {
    const showUAELicenceDetailsFieilds = this.validateUAELicenceFields();
    const showLicenseEmirateFieild = this.validateLicenseEmirateField();
    const showEmirateFieild = this.validateEmirateField();
    const showVisaOccupationOther = this.validateVisaProfessionOther();
    const hideHasLicenceField = this.hideHasLicenceField();
    const showLicenceDetailsFieilds =
      !hideHasLicenceField && this.validateLicenceFields();
    const showCabinCrewOrPilotOption = this.validateCabinCrewOrPilotOption();

    return (
      <form onSubmit={this.submitHandler}>
        <div className="row">
          {this.renderFormHeader()}
          {/* <div className="col-md-6 col-12">{this.renderNationalityInput()}</div> */}
          {/* {showEmirateFieild && (
            <div className="col-md-6 col-12">{this.renderEmirateInput()}</div>
          )} */}

          <div className="col-md-12 col-12">
            {this.renderLocationAddressInput()}
          </div>
          <div className="col-md-6 col-12">{this.renderOccupationInput()}</div>
          <div className="col-md-6 col-12">
            {this.renderVisaProfessionInput()}
          </div>
          {showVisaOccupationOther && (
            <div className="col-md-6 col-12">
              {this.renderVisaProfessionOtherInput()}
            </div>
          )}

          {showCabinCrewOrPilotOption && (
            <div className="col-md-6 col-12">
              {this.renderCabinCrewOrPilotInput()}
            </div>
          )}
          <div className="col-md-6 col-12">
            {this.renderHasUAELicenceInput()}
          </div>
          {showUAELicenceDetailsFieilds && (
            <div className="col-12 mb-3">
              {this.state.fields.UAELicenses.value.map(
                (uaeLicense: UAELicenseDetails, index: number) => (
                  <UAELicenseTypeForm
                    // key={`${index}--${uaeLicense.licenseEmirate}--${uaeLicense.licenseTypeId}`}
                    index={index}
                    licenceInfo={uaeLicense}
                    showRemove={
                      this.state.fields.UAELicenses.value.length !== 1
                    }
                    validate={this.state.validateUAELicences}
                    onChange={(iIndex, data) =>
                      this.onUAELicensTypeChange(iIndex, data)
                    }
                    onDelete={(iIndex) => this.deleteUAELicenseEntry(iIndex)}
                  ></UAELicenseTypeForm>
                )
              )}
              <Button
                type="button"
                btnlink
                onClick={() => this.addUAELicenceOption()}
              >
                + Add Another UAE License
              </Button>
            </div>
          )}
          {!hideHasLicenceField && (
            <div className="col-md-6 col-12">
              {this.renderHasLicenceInput()}
            </div>
          )}
          {showLicenceDetailsFieilds && (
            <div className="col-md-6 col-12">{this.renderLicenceDates()}</div>
          )}
          {showLicenceDetailsFieilds && (
            <div className="col-md-6 col-12">
              {this.renderLicenceCountryInput()}
            </div>
          )}
          {showLicenceDetailsFieilds && showLicenseEmirateFieild && (
            <div className="col-md-6 col-12">
              {this.renderLicenceEmirateInput()}
            </div>
          )}
        {( this.state.fields?.licenseCountry?.value === this.state?.fields?.nationality?.value && showLicenceDetailsFieilds )&&
          <>
              <label className="Input__label w-100"><b>You are eligle for Golden Opportunity course</b></label>
              <p></p>
              {this.renderGoldenOpertunityCustomerInput()}
            
          </>
        }

          <div className="col-12 mt-2">{this.renderSubmitButton()}</div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state: any) => ({
  isLoading: state.registration.isLoading,
  user: state.registration.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  onSubmit: (details: any, fromRTA?:boolean) =>
    dispatch(
      registrationActions.updateDetails(details, () => {
        return dispatch(registrationActions.getCourses(true, fromRTA === true ? false:true))
      })
    ),
  fetchProfile: () => dispatch(userActions.fetchProfile()),
});

// PersonalDetail.propTypes = {
//   isLoading: PropTypes.bool.isRequired,
//   onSubmit: PropTypes.func.isRequired,
//   addSummary: PropTypes.func.isRequired,
//   user: PropTypes.shape({
//     nationality: PropTypes.string,
//     studentDetails: PropTypes.shape({
//       hasLicense: PropTypes.bool,
//       emirate: PropTypes.string,
//       occupation: PropTypes.string,
//       licenseCountry: PropTypes.string,
//       visaProfession: PropTypes.string,
//       licenseIssueDate: PropTypes.string,
//       licenseExpiryDate: PropTypes.string,
//       licenseEmirate: PropTypes.string,
//       requiredLicenseCategory: PropTypes.string,
//     }),
//   }),
//   gotoNextBlock: PropTypes.func.isRequired,
//   fetchProfile: PropTypes.func.isRequired,
// };

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetail);
